<template>
  <div class="row">
    <div class="col-12">
      <octo-header-button :buttons="headerButtons" @onWizardCampaign="$router.push({name: 'leads.campaigns.show', params: {id : 'new' }})"/>
    </div>
    <div class="col-md-12">
      <campaign-datatable/>
    </div>
  </div>
</template>

<script>
import CampaignDatatable from "@/pages/Leads/datatables/CampaignDatatable";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";

export default {
  name: "CampaignIndexPage",
  components: {OctoHeaderButton, CampaignDatatable},
  data() {
    return {
      headerButtons: [
        {
          label: 'new_campaign',
          onClick: 'onWizardCampaign'
        }
      ],
    }
  },
}
</script>

<style scoped>

</style>
