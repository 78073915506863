<template>
  <octo-table
    :enable-store="false"
    :action="endpoints.DATATABLES.campaigns"
    :fields="fields"
    :filters="filters"
    name="CampaignTable"
    @onResetFilters="resetFilters"
  >

    <template v-slot:auto="data">
      <octo-icon v-if="data.row.auto" class="text-success" icon="check"/>
    </template>

    <template v-slot:leads="data">
      {{ data.row.call_center_leads}}
    </template>

    <template v-slot:name="data">
      <div class="small text-uppercase">{{ data.row.name}}</div>
    </template>

    <template v-slot:origin="data">
      <div class="small text-uppercase">{{ data.row.origin_name}}</div>
    </template>

    <template v-slot:providers="data">
      <div class="small text-uppercase">{{ data.row.providers}}</div>
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">
        <el-tooltip content="Settings"
                    :open-delay="300"
                    placement="top">
          <base-button
            class="mx-1"
            @click="$router.push({name: 'leads.campaigns.show', params: {id : data.row.id }})"
            link
            size="sm"
            icon
          >
            <octo-icon icon="right-arrow"/>
          </base-button>
        </el-tooltip>
      </div>

    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";

export default {
  name: "CampaignDatatable",
  components: {OctoIcon, OctoTable},
  data() {
    return {
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'name', label: 'name', align: 'left', slot: true, fixed:true},
        {prop: 'leads', label: 'leads', width: 100, align: 'center', slot: true},
        {prop: 'priority', label: 'priority', width: 100, align: 'center'},
        {prop: 'auto', label: 'auto', width: 100, align: 'center',slot: true},
        {prop: 'origin', label: 'origin_campaign', width: 250, slot: true, align: 'left'},
        {prop: 'actions', label: 'actions', width: 60, slot: true, fixed: 'right'},
      ]
    }
  },
  methods: {
    resetFilters: function () {
      this.filters = {

      };
    },
  }
}
</script>

<style scoped>

</style>
